import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import history from '../history';
import './donation-options.scss';
import CurrencyInput from 'react-currency-input';
import Modal from 'react-modal';
import Terms from '../pages/page-terms';

const codes = [
	{currency: 'United States Dollar', code: 'USD'},
	{currency: 'Afghan Afghani', code: 'AFN'},
	{currency: 'Albanian Lek', code: 'ALL'},
	{currency: 'Algerian Dinar', code: 'DZD'},
	{currency: 'Angolan Kwanza', code: 'AOA'},
	{currency: 'Argentine Peso', code: 'ARS'},
	{currency: 'Armenian Dram', code: 'AMD'},
	{currency: 'Aruban Florin', code: 'AWG'},
	{currency: 'Australian Dollar', code: 'AUD'},
	{currency: 'Azerbaijani Manat', code: 'AZN'},
	{currency: 'Bahamian Dollar', code: 'BSD'},
	{currency: 'Bangladeshi Taka', code: 'BDT'},
	{currency: 'Barbadian Dollar', code: 'BBD'},
	{currency: 'Belize Dollar', code: 'BZD'},
	{currency: 'Bermudian Dollar', code: 'BMD'},
	{currency: 'Bolivian Boliviano', code: 'BOB'},
	{currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM'},
	{currency: 'Botswana Pula', code: 'BWP'},
	{currency: 'Brazilian Real', code: 'BRL'},
	{currency: 'British Pound', code: 'GBP'},
	{currency: 'Brunei Dollar', code: 'BND'},
	{currency: 'Bulgarian Lev', code: 'BGN'},
	{currency: 'Burundian Franc', code: 'BIF'},
	{currency: 'Cambodian Riel', code: 'KHR'},
	{currency: 'Canadian Dollar', code: 'CAD'},
	{currency: 'Cape Verdean Escudo', code: 'CVE'},
	{currency: 'Cayman Islands Dollar', code: 'KYD'},
	{currency: 'Central African Cfa Franc', code: 'XAF'},
	{currency: 'Cfp Franc', code: 'XPF'},
	{currency: 'Chilean Peso', code: 'CLP'},
	{currency: 'Chinese Renminbi Yuan', code: 'CNY'},
	{currency: 'Colombian Peso', code: 'COP'},
	{currency: 'Comorian Franc', code: 'KMF'},
	{currency: 'Congolese Franc', code: 'CDF'},
	{currency: 'Costa Rican Colón', code: 'CRC'},
	{currency: 'Croatian Kuna', code: 'HRK'},
	{currency: 'Czech Koruna', code: 'CZK'},
	{currency: 'Danish Krone', code: 'DKK'},
	{currency: 'Djiboutian Franc', code: 'DJF'},
	{currency: 'Dominican Peso', code: 'DOP'},
	{currency: 'East Caribbean Dollar', code: 'XCD'},
	{currency: 'Egyptian Pound', code: 'EGP'},
	{currency: 'Ethiopian Birr', code: 'ETB'},
	{currency: 'Euro', code: 'EUR'},
	{currency: 'Falkland Islands Pound', code: 'FKP'},
	{currency: 'Fijian Dollar', code: 'FJD'},
	{currency: 'Gambian Dalasi', code: 'GMD'},
	{currency: 'Georgian Lari', code: 'GEL'},
	{currency: 'Gibraltar Pound', code: 'GIP'},
	{currency: 'Guatemalan Quetzal', code: 'GTQ'},
	{currency: 'Guinean Franc', code: 'GNF'},
	{currency: 'Guyanese Dollar', code: 'GYD'},
	{currency: 'Haitian Gourde', code: 'HTG'},
	{currency: 'Honduran Lempira', code: 'HNL'},
	{currency: 'Hong Kong Dollar', code: 'HKD'},
	{currency: 'Hungarian Forint', code: 'HUF'},
	{currency: 'Icelandic Króna', code: 'ISK'},
	{currency: 'Indian Rupee', code: 'INR'},
	{currency: 'Indonesian Rupiah', code: 'IDR'},
	{currency: 'Israeli New Sheqel', code: 'ILS'},
	{currency: 'Jamaican Dollar', code: 'JMD'},
	{currency: 'Japanese Yen', code: 'JPY'},
	{currency: 'Kazakhstani Tenge', code: 'KZT'},
	{currency: 'Kenyan Shilling', code: 'KES'},
	{currency: 'Kyrgyzstani Som', code: 'KGS'},
	{currency: 'Lao Kip', code: 'LAK'},
	{currency: 'Lebanese Pound', code: 'LBP'},
	{currency: 'Lesotho Loti', code: 'LSL'},
	{currency: 'Liberian Dollar', code: 'LRD'},
	{currency: 'Macanese Pataca', code: 'MOP'},
	{currency: 'Macedonian Denar', code: 'MKD'},
	{currency: 'Malagasy Ariary', code: 'MGA'},
	{currency: 'Malawian Kwacha', code: 'MWK'},
	{currency: 'Malaysian Ringgit', code: 'MYR'},
	{currency: 'Maldivian Rufiyaa', code: 'MVR'},
	{currency: 'Mauritanian Ouguiya', code: 'MRO'},
	{currency: 'Mauritian Rupee', code: 'MUR'},
	{currency: 'Mexican Peso', code: 'MXN'},
	{currency: 'Moldovan Leu', code: 'MDL'},
	{currency: 'Mongolian Tögrög', code: 'MNT'},
	{currency: 'Moroccan Dirham', code: 'MAD'},
	{currency: 'Mozambican Metical', code: 'MZN'},
	{currency: 'Myanmar Kyat', code: 'MMK'},
	{currency: 'Namibian Dollar', code: 'NAD'},
	{currency: 'Nepalese Rupee', code: 'NPR'},
	{currency: 'Netherlands Antillean Gulden', code: 'ANG'},
	{currency: 'New Taiwan Dollar', code: 'TWD'},
	{currency: 'New Zealand Dollar', code: 'NZD'},
	{currency: 'Nicaraguan Córdoba', code: 'NIO'},
	{currency: 'Nigerian Naira', code: 'NGN'},
	{currency: 'Norwegian Krone', code: 'NOK'},
	{currency: 'Pakistani Rupee', code: 'PKR'},
	{currency: 'Panamanian Balboa', code: 'PAB'},
	{currency: 'Papua New Guinean Kina', code: 'PGK'},
	{currency: 'Paraguayan Guaraní', code: 'PYG'},
	{currency: 'Peruvian Nuevo Sol', code: 'PEN'},
	{currency: 'Philippine Peso', code: 'PHP'},
	{currency: 'Polish Złoty', code: 'PLN'},
	{currency: 'Qatari Riyal', code: 'QAR'},
	{currency: 'Romanian Leu', code: 'RON'},
	{currency: 'Russian Ruble', code: 'RUB'},
	{currency: 'Rwandan Franc', code: 'RWF'},
	{currency: 'São Tomé and Príncipe Dobra', code: 'STD'},
	{currency: 'Saint Helenian Pound', code: 'SHP'},
	{currency: 'Salvadoran Colón', code: 'SVC'},
	{currency: 'Samoan Tala', code: 'WST'},
	{currency: 'Saudi Riyal', code: 'SAR'},
	{currency: 'Serbian Dinar', code: 'RSD'},
	{currency: 'Seychellois Rupee', code: 'SCR'},
	{currency: 'Sierra Leonean Leone', code: 'SLL'},
	{currency: 'Singapore Dollar', code: 'SGD'},
	{currency: 'Solomon Islands Dollar', code: 'SBD'},
	{currency: 'Somali Shilling', code: 'SOS'},
	{currency: 'South African Rand', code: 'ZAR'},
	{currency: 'South Korean Won', code: 'KRW'},
	{currency: 'Sri Lankan Rupee', code: 'LKR'},
	{currency: 'Surinamese Dollar', code: 'SRD'},
	{currency: 'Swazi Lilangeni', code: 'SZL'},
	{currency: 'Swedish Krona', code: 'SEK'},
	{currency: 'Swiss Franc', code: 'CHF'},
	{currency: 'Tajikistani Somoni', code: 'TJS'},
	{currency: 'Tanzanian Shilling', code: 'TZS'},
	{currency: 'Thai Baht', code: 'THB'},
	{currency: 'Tongan Paʻanga', code: 'TOP'},
	{currency: 'Trinidad and Tobago Dollar', code: 'TTD'},
	{currency: 'Turkish Lira', code: 'TRY'},
	{currency: 'Ugandan Shilling', code: 'UGX'},
	{currency: 'Ukrainian Hryvnia', code: 'UAH'},
	{currency: 'United Arab Emirates Dirham', code: 'AED'},
	{currency: 'Uruguayan Peso', code: 'UYU'},
	{currency: 'Uzbekistani Som', code: 'UZS'},
	{currency: 'Vanuatu Vatu', code: 'VUV'},
	{currency: 'Vietnamese Đồng', code: 'VND'},
	{currency: 'West African Cfa Franc', code: 'XOF'},
	{currency: 'Yemeni Rial', code: 'YER'},
	{currency: 'Zambian Kwacha', code: 'ZMW'},
  ]

class DonationOptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: '',
			charityId: 0,
			terms: false,
			showModal: false,
			currency: '',
		};

		this.cancel = '';

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleAmountChange = this.handleAmountChange.bind(this);
		this.handleTermsChange = this.handleTermsChange.bind(this);
		this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
		this.handleRouting = this.handleRouting.bind(this);
		this.handleACHRouting = this.handleACHRouting.bind(this);
		this.handleCharityChanged = this.handleCharityChanged.bind(this);
	}

	handleAmountChange(event) {
		var amount = event.target.value.replace(/[^0-9 ]/g, '');
		this.setState({ amount: amount });
	}

	handleAmountChange(event, maskedvalue, floatvalue) {
		this.setState({ amount: floatvalue });
	}

	handleTermsChange(event) {
		this.setState({ terms: event.target.checked });
	}

	handleCurrencyChange(event) {
		this.setState({ currency: event.target.value });
	}

	handleCharityChanged(event) {
		this.setState({ charityId: event.target.value });
	}

	async handleRouting() {
		if (this.state.amount >= 1 && this.state.terms && this.state.charityId !== '' && this.state.currency !== '') {
			history.push({
				pathname: '/donate',
				state: {
					amount: this.state.amount,
					currency: this.state.currency,
					charityId: this.state.charityId
				},
			});
		} else {
			alert(
				`Please make sure you have provided a donation amount of at least $1 and agreed to the terms and conditions.`
			);
		}
	}

	handleACHRouting() {
		if (this.state.amount >= 1 && this.state.terms && this.state.charityId !== '' && this.state.currency !== '') {
			if (this.state.currency !== 'USD') {
				alert(
					`Donations via Bank Transfer must be in USD.`
				);
			} else {
				history.push({
					pathname: '/donate-ach',
					state: {
						amount: this.state.amount,
						currency: this.state.currency,
						charityId: this.state.charityId
					},
				});
			}
		} else {
			alert(
				`Please make sure you have provided a donation amount of at least $1 and agreed to the terms and conditions.`
			);
		}
	}

	handleOpenModal() {
		this.setState({ showModal: true });
	}

	handleCloseModal() {
		this.setState({ showModal: false });
	}

	render() {
		return (
			<div className='donation-container'>
				<div className='donation-container-help'>
					<p>HELP BY DONATING</p>

					<select
						className='select'
						onChange={this.handleCharityChanged}
						value={this.state.charityId}
					>
						<option value="">Select a charity</option>
						<option value={913288}>Nova Ukraine</option>
						<option value={899398}>Razom</option>
						<option value={1000046}>Revived Soldiers Ukraine</option>
						<option value={54917}>Save the Children Ukraine Fund</option>
						<option value={1051340}>UN Refugee Agency</option>
  					</select>

					<select
						className='select'
						onChange={this.handleCurrencyChange}
						value={this.state.currency}
					>
						<option value="usd">Select your currency*</option>
						{
							codes.map((currency, index) => {
								return <option value={currency.code}>{currency.code}</option>
							})
						}
  					</select>

					<CurrencyInput
						className='currencyInput'
						prefix=''
						value={this.state.amount}
						onChangeEvent={this.handleAmountChange}
					/>

					<div className="terms">
						<h5><input type="checkbox" onChange={this.handleTermsChange} value={this.state.terms}/>I accept the <Link onClick={this.handleOpenModal}>Terms & Conditions</Link></h5>
						<Modal isOpen={this.state.showModal}
						onRequestClose={this.handleCloseModal}
						className="Modal"
						overlayClassName="Overlay"
						contentLabel="Terms and Conditions">
						<Terms closeModal={this.handleCloseModal}/>
						</Modal>
					</div>

					<div className='button'>
						<button onClick={this.handleRouting}>Donate via Credit Card</button>
					</div>

					<div className='button'>
						<button onClick={this.handleACHRouting}>Donate via Bank Transfer (USD Only)</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DonationOptions);
