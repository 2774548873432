import React, { Component } from 'react';
import { Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Donate from './components/donate.js';
import DonationOptions from './components/donation-options.js';
import LearnMoreButton from './components/learn-more.js';
import SocialComponent from './components/social-component.js';
import Checkout from './pages/page-donate.js';
import { Link, withRouter } from 'react-router-dom';
import heroImage from './images/hero_image.png';
import './App.scss';
const data = require('./data/data.json');

class App extends Component {
	renderBody = () => {
		return (
			<div>
				{data.body.map((paragraph) => {
					return <p>{paragraph}</p>;
				})}
			</div>
		);
	};

	render() {
		return (
			<div>
				<Header />
				<div className='topTitle'>
					<h1>{data.title}</h1>
				</div>
				<div className='row'>
					<div className='double-column'>
						<div className='column'>
							<div className='section-main'>
								<div className='section-main-title'>
									<img src={heroImage}></img>
									<div style={{ minWidth: '300px' }}></div>
								</div>

								<div className='section-main-description'>
									<p>
										We stand with the Ukrainian people and our teammates.
										GlobalLogic is working around the clock to ensure the
										wellbeing of our colleagues, and through this program
										extending our support to their countrywomen, men, and
										children.
									</p>
									<p>
										Your donations will facilitate urgently needed relief
										services to the courageous people of Ukraine.
									</p>
									<p>
										We have selected these five charities to focus our efforts.
										<br />
										<i>US-Based Ukrainian Organizations</i>
										<br />
										<b>Nova Ukraine</b> - Emergency resources to both refugees
										and citizens who have remained in Ukraine
										<br />
										<b>Razom</b> - Emergency response providing critical medical
										supplies to hospitals and citizens
										<br />
										<b>Revived Soldiers Ukraine</b> - Providing aid in support
										of fundamental human rights and medical rehabilitation for
										soldiers
										<br />
										<br />
										<br />
										<i>Global Aid Organizations</i>
										<br />
										<b>Save the Children Ukraine Fund</b> - children and family
										assistance
										<br />
										<b>United Nations High Commissioner for Refugees</b> -
										refugee assistance
										<br />
									</p>

									<p>
										GlobalLogic will pay all applicable processing fees so that
										100 percent of your donation goes to the charitable
										organization you choose. You will receive a receipt via
										email upon your donation. Your donation will be collected by
										the Givinga Foundation and then remitted directly to the
										charity you choose.
									</p>
									
									<br/>
									<p>
									If you would like to make a contribution to this initiative through a donor-advised fund, please follow the instructions outlined here: <a href="https://res.cloudinary.com/hyetmyubn/image/upload/v1647294878/Avery%20Assets/GlobalLogic_Ukraine_DAF_Instructions.pdf" target="_blank">GlobalLogic Humanitarian Aid for Ukraine Donor-Advised Fund Giving Instructions</a>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className='column'>
						<div className='section-donate'>
							<Donate />
							<DonationOptions />
							<SocialComponent />
							<div class='section-donate-disclaimer'>
								*While we work to ensure accuracy, the stats indicated on this
								page may not be an exact reflection of actual activity.
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withRouter(App);
