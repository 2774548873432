import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	CardElement,
	Elements,
	ElementsConsumer,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import qs from 'querystring';
import Header from '../components/header';
import Footer from '../components/footer.js';
import { Link, withRouter } from 'react-router-dom';
import history from '../history';
import './page-donate.scss';
import { getCheckout } from "../api/getCheckout";
const currencies = require('../data/currencies.json');

const charityNames = {
	913288: "Nova",
	899398: "Razom",
	1000046: "Revived Soldiers Ukraine",
	54917: "Save the Children Ukraine Fund",
	1051340: "UN Refugee Agency",
};  

class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			email: '',
			name: '',
			coverCCFee: false,
			currency: props.currency,
			charityId:  props.charityId
		};

		this.emailUpdate = this.emailUpdate.bind(this);
		this.nameUpdate = this.nameUpdate.bind(this);
	}

	emailUpdate(event) {
		this.setState({ email: event.target.value });
	}

	nameUpdate(event) {
		this.setState({ name: event.target.value });
	}

	handleSubmit = async (event) => {
		if (this.state.name === '' || this.state.email === '') {
			this.setState({ processing: false });
			alert('Please provide your name and email.');
			event.preventDefault();
			return;
		}
		const { amount } = this.props;
		this.setState({ processing: true });
		event.preventDefault();
		const calculatedAmount = currencies[this.state.currency.toLowerCase()] * amount;


		//here we are going to refactor to an API call. 
		//TODO: name and email!
		const checkout = await getCheckout(calculatedAmount, this.state.currency, this.state.charityId, this.state.name, this.state.email);
		const stripePromise = loadStripe(checkout.response.publicKey);
		const stripe = await stripePromise;
		const result = await stripe.redirectToCheckout({
			sessionId: checkout.response.checkoutId,
		});
	};

	render() {
		const { stripe } = this.props;
		return (
			<div>
				<Header />
				<center>
					<div className='header'>
						Donate{' '}
						<span style={{ color: '#E05050' }}>{this.props.amount}{this.props.currency}</span> to <span style={{ color: '#E05050' }}>{charityNames[this.props.charityId]}</span>
					</div>
				</center>
				<div className='donate-page-container'>
					<div className='donate-page-container-title'>
						<center>
							<form onSubmit={this.handleSubmit}>
								<div className='checkout-form'>
									<input
										placeholder='Name*'
										value={this.state.name}
										onChange={this.nameUpdate}
									/>
									<input
										placeholder='Email*'
										value={this.state.email}
										onChange={this.emailUpdate}
									/>
								</div>
								<hr />
								<div className='note'>
								If applicable, please use your company email address so that we can identify contributions from our partners.
								</div>
								<button type='submit' disabled={this.state.processing}>
									{this.state.processing ? 'Processing...' : 'Donate Now'}
								</button>
							</form>
						</center>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

class DonateForm extends Component {
	render() {
		return (
			<CheckoutForm amount={this.props.location.state.amount} 
						  charityId={parseInt(this.props.location.state.charityId)} 
						  currency={this.props.location.state.currency} />
		);
	}
}

export default withRouter(DonateForm);
