import axios from "../utils/axios";

export const getDonationDetail = async (session_id) => {
  const {
    data: { response, error },
  } = await axios.post(`/.netlify/functions/fetch-donation-detail`, {session_id}, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return { error, response };
};
