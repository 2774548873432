import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer.js';
import SocialComponent from '../components/social-component';
import { withRouter } from 'react-router-dom';
import './page-thank-you.scss';
import hearts from '../images/thank-you-hearts.png';
import { getDonationDetail } from '../api/getDonationDetail';

const ThankYouACHPage = () => {
	return (
		<div>
			<Header />
			<div className='container-page-thank-you'>
				<div className='container-page-thank-you-title'>
					<center>
						<img className='img-container' src={hearts}></img>
						<h1>Thank you for donating!</h1>
					</center>
				</div>
			</div>
			<div className='receipt'>
            A link to view and pay your donation invoice has been sent to the email you provided.
			</div>
			<div className='thank-you-social-container'>
				<div className='thank-you-social-container-help'>
					<p>HELP BY SHARING</p>
				</div>
				<div className='thank-you-social-container-icons'>
					<center>
						<div data-network='facebook' className='st-custom-button'>
							<img src={require('../images/social/facebook.png')} />
						</div>
						<div data-network='twitter' className='st-custom-button'>
							<img src={require('../images/social/twitter.png')} />
						</div>
						<div data-network='linkedin' className='st-custom-button'>
							<img src={require('../images/social/linkedin.png')} />
						</div>
						<div data-network='email' className='st-custom-button'>
							<img src={require('../images/social/email.png')} />
						</div>
					</center>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default withRouter(ThankYouACHPage);
