import axios from "../utils/axios";

export const getAnalytics = async () => {
  const {
    data: { response, error },
  } = await axios.get(`/.netlify/functions/fetch-donation-analytics`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return { error, response };
};
