import React from 'react';
import './learn-more.scss';

class LearnMoreButton extends React.Component {
   render() {
      return (
         <div className="learn-more-container">
          <p>Learn More</p>
         </div>
      );
   }
}

export default LearnMoreButton;
