import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../images/wespire.png';

class Footer extends React.Component {
   render() {
      return (
       <div className="footer-container">
        <div className="footer-container-icon">
          Powered By<img src={logo} className="footer-container-icon-image"></img>
        </div>
        <div className="footer-container-copy">
        Copyright Wespire, 2022. All rights reserved. | <a href="https://www.wespire.com/privacy-policy/" target="_blank">Privacy Policy</a>
        </div>
       </div>
      );
   }
}

export default Footer;